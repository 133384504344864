.header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background-color: var(--body-color);
    z-index: var(--z-fixed);
    padding-top: 1rem;
}
   
.nav__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: var(--header-height);
}
   
.nav__logo {
    color: var(--first-color);
    transition: color 0.4s;
    font-family: var(--header-font);
    font-weight: var(--font-semi-bold);
    font-size: larger;
    cursor: pointer;
}
   
.nav__toggle,
.nav__close {
    font-size: 1.5rem;
    color: var(--title-color);
    cursor: pointer;
}

.nav__list {
    display: flex;
    flex-direction: row;
    column-gap: 2.5rem;
}
   
.nav__link {
    color: var(--title-color);
    font-weight: var(--font-semi-bold);
    transition: color 0.4s;
}

.nav__link:hover {
    color: var(--first-color);
    cursor: pointer;
}

.nav__close {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
}

.show-menu {
    right: 0;
}

@media screen and (max-width: 849px) {
    .nav__close, .nav__toggle {
        display: none;
    }

    .nav-hamburger{
        display: flex;
    }

    .nav__menu{
        position: fixed;
        top: 0;
        right: -1em;
        width: 100%;
        height: calc(100% + 2em);
        /* gradient background color */
        background: linear-gradient(0deg, rgba(40, 40, 40, 0.8), var(--body-color));
        transform: translateY(-20px);
        transition: color 1.0s ease, opacity 0.3s ease, transform 0.3s ease;
    }

    .close-button{
        margin-top: 2em;
    }
    
    .nav__menu.show {
        opacity: 1;
        transform: translateY(0);
    }

    .nav__list{
        display: flex;
        flex-direction: column;
        align-items: center;
        /* gap */
        row-gap: 2rem;
        padding-top: 3rem;
    }

    .nav__link{
        font-size: 1.5rem;
    }

    .nav__link:hover{
        color: var(--first-color);
        cursor: pointer;
    }

    .nav__list {
        display: flex;
        flex-direction: column;
    }
}