ul {
    list-style: none;
}

a {
    text-decoration: none;
}

/* montserrat */
@font-face {
    font-family: "Montserrat";
    src: url("fonts/Montserrat-VariableFont_wght.ttf");
}

/* californian fb */
@font-face {
    font-family: "Californian FB";
    src: url("fonts/californian-fb-bold.ttf");
}

* {
 box-sizing: border-box;
 padding: 0;
 margin: 0;
}

:root {
 --header-height: 3.5rem;
 --first-color: hsl(28, 88%, 62%);
 --title-color: hsl(0, 0%, 95%);
 --text-color: hsl(0, 0%, 75%);
 --body-color: hsl(0, 0%, 6%);
 --highlight-color: hsla(0, 0%, 50%, 0.2);
 --background-color: hsla(0, 0%, 20%, 0.2);
 --body-font: "Montserrat", sans-serif;
 --portfolio-font: "Californian FB", serif;
 --header-font: "Montserrat", sans-serif;
 --h2-font-size: 1.25rem;
 --small-font-size: 0.813rem;
 --font-semi-bold: 600;
 --z-tooltip: 10;
 --z-fixed: 100;
 --portfolio-margin: 2em;
 --portfolio-first-margin: 2em;
}


body {
    background-color: var(--body-color);
    color: var(--text-color);
    font-family: var(--body-font);
   }